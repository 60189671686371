import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Paper, Grid, TextField, Popover, Button, FormControlLabel, Switch } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { useStyles } from './AddNewBillboard.css';
import { pressType, billboardsTypesFacade, billboardClassification, groungHeight, lightingType, orientationType, minPeriod, facade, mediaOwnerType } from '../Autocomplete.options';
import Icon from '../IconsComponent';
import { stepOneValidations, emptyValidations } from "../Validations";
import { InputNewBillboard } from './InputNewBillboard';
import { AutocompleteNewBillboard } from './AutocompleteNewBillboard';
import { SwitchNewBillboard } from './SwitchNewBillboard';

export const Parameters = ({ handleStepsArray, setSelectedFile, selectedFile, previewImage, setPreviewImage, disabled }: any) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const hiddenFileInput = useRef(null);

  const billboard = useSelector(ContentSelectors.getNewBillboard)

  let url = window.location.origin
  if (url.includes("8080") || url.includes("3000")) {
    url = "https://ssp-dev.billalo.it"
  }
  let imageUrl = `${url}/resources/`
  // imageUrl = imageUrl + newBillboard.id + "_" + selectedFile.name

  const setValues = (key: string, value: any, setFieldValue: any) => {
    setFieldValue(key, value)
    dispatch(ContentActions.setBillboard({ key: key, value: value }))
  }

  return (
    <Grid container>
      <Grid className="mb-2" container item xs={12} alignItems="center" justify="center">
        <Grid item xs={11}>
          <Button
            data-cy="buttonUploadImage"
            className={`${classes.margin} border`}
            startIcon={<Icon size={20} color="#bfbfbf" icon="add" />}
            size="small"
            onClick={() => (hiddenFileInput.current as any).click()}
          >
            {billboard.imageUrl ? "Modifica foto" : "Aggiungi Foto"}
          </Button>
          <input
            type="file"
            accept='.jpg, .png, .jpeg'
            ref={hiddenFileInput}
            hidden
            data-cy="inputUploadImage"
            onChange={(e: any) => {
              setSelectedFile(e.target.files[0])
              setPreviewImage(URL.createObjectURL(e.target.files[0]))
              dispatch(ContentActions.setBillboard({ key: 'imageUrl', value: imageUrl + billboard.id + "_" + e.target.files[0].name }))
              // console.log("image url", imageUrl + billboard.id + "_" + e.target.files[0].name)
            }}
          />
        </Grid>

      </Grid>
      {previewImage !== ""
        ? <Grid className="mb-4" container item xs={12} alignItems="center" justify="center">
          <Grid item xs={11}>
            <img style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }} src={previewImage} width="100%" height="100%" />
          </Grid>
        </Grid>
        : null}
      <Grid container item xs={12} alignItems="center" justify="center">
        <Formik
          initialValues={billboard}
          validationSchema={billboard.billboard_class
            ? stepOneValidations[billboard.billboard_class]
            : emptyValidations /// senza di questo crasha
          }
          onSubmit={values => {
            console.log("another step", values)
            dispatch(UiActions.didSetStep('Localizzazione'))
            handleStepsArray('Localizzazione')
          }}>
          {({ errors, touched, values, handleChange, setFieldValue }) => {
            const propsToPass = {
              errors: errors,
              touched: touched,
              values: values,
              setFieldValue: setFieldValue
            }

            return (
              <Form className="poppinsFont detailText w-100">
                <h5 className="ml-4" >Parametri</h5>

                <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                  <AutocompleteNewBillboard data={billboard} name={'billboard_class'} label={'Classe *'} options={billboardClassification} disabled={disabled} {...propsToPass} />
                  {billboard?.billboard_class ?
                    billboard?.billboard_class === "Outdoor" ?
                      <Grid container item xs={5} alignItems="center">
                        <span style={{ fontSize: 13, marginRight: 10 }}>Spazio visibile per l'acquisto</span>
                        <SwitchNewBillboard data-cy="visible-checkbox" defaultChecked={true} checked={billboard.visible} onChange={() => { setValues('visible', !billboard.visible, setFieldValue) }} name="visible" />
                      </Grid>
                      : <AutocompleteNewBillboard data={billboard} name={'media_type'} label={'Tipologia *'} options={billboardsTypesFacade[billboard.billboard_class]} {...propsToPass} />
                    : <Grid item xs={5} />}
                </Grid>

                {billboard.billboard_class &&

                  billboard.billboard_class === "Outdoor" &&
                  <>
                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <AutocompleteNewBillboard data={billboard} name={'press_type'} label={'Tipo contenuto'} options={pressType[billboard.billboard_class]} {...propsToPass} />
                      <AutocompleteNewBillboard data={billboard} name={'media_type'} label={'Tipologia *'} options={billboardsTypesFacade[billboard.billboard_class]} {...propsToPass} />
                    </Grid>

                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <InputNewBillboard data={billboard} name={'base'} label={'Base *'} type={'number'} disabled={disabled} {...propsToPass} />
                      <InputNewBillboard data={billboard} name={'height'} label={'Altezza *'} type={'number'} disabled={disabled} {...propsToPass} />
                    </Grid>

                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <InputNewBillboard data={billboard.base * billboard.height} name={'size'} label={'Superficie (cmq)'} type={'text'} readOnly {...propsToPass} />
                      <InputNewBillboard data={billboard.base || billboard.height ? `${billboard.base}x${billboard.height}` : ''} name={'format'} label={'Formato'} type={'text'} readOnly {...propsToPass} />
                    </Grid>

                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <AutocompleteNewBillboard data={billboard} name={'height_ground'} label={'Altezza da terra *'} options={groungHeight} {...propsToPass} />
                      <AutocompleteNewBillboard data={billboard} name={'lighting'} label={'Illuminazione *'} options={lightingType} {...propsToPass} />
                    </Grid>

                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <AutocompleteNewBillboard data={billboard} name={'orientation'} label={'Orientamento *'} options={orientationType} {...propsToPass} />
                      <InputNewBillboard data={billboard} name={'cod_cimasa'} label={'Codice Cimasa *'} type={'text'} {...propsToPass} />
                    </Grid>

                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <InputNewBillboard data={billboard} name={'price'} label={'Prezzo *'} type={'number'} {...propsToPass} />
                      <AutocompleteNewBillboard data={billboard} name={'min_period'} label={'Uscita minima'} options={minPeriod} {...propsToPass} />
                    </Grid>

                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <AutocompleteNewBillboard data={billboard} name={'facade'} label={'Facciate'} options={facade} {...propsToPass} />
                      <Grid item xs={5} />
                    </Grid>
                  </>

                  ||

                  billboard.billboard_class === "Indoor" &&
                  <>
                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <AutocompleteNewBillboard data={billboard} name={'press_type'} label={'Tipo contenuto'} options={pressType[billboard.billboard_class]} {...propsToPass} />
                      <AutocompleteNewBillboard data={billboard} name={'facade'} label={'Facciate'} options={facade} {...propsToPass} />
                    </Grid>

                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <InputNewBillboard data={billboard} name={'base'} label={'Base *'} type={'number'} disabled={disabled} {...propsToPass} />
                      <InputNewBillboard data={billboard} name={'height'} label={'Altezza *'} type={'number'} disabled={disabled} {...propsToPass} />
                    </Grid>

                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <InputNewBillboard data={billboard.base * billboard.height} name={'size'} label={'Superficie (cmq)'} type={'text'} readOnly {...propsToPass} />
                      <InputNewBillboard data={billboard.base || billboard.height ? `${billboard.base}x${billboard.height}` : ''} name={'format'} label={'Formato'} type={'text'} readOnly {...propsToPass} />
                    </Grid>

                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <AutocompleteNewBillboard data={billboard} name={'height_ground'} label={'Altezza da terra *'} options={groungHeight} {...propsToPass} />
                      <InputNewBillboard data={billboard} name={'cod_cimasa'} label={'Codice *'} type={'text'} {...propsToPass} />
                    </Grid>

                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <AutocompleteNewBillboard data={billboard} name={'min_period'} label={'Uscita minima'} options={minPeriod} {...propsToPass} />
                      <InputNewBillboard data={billboard} name={'price'} label={'Prezzo *'} type={'number'} {...propsToPass} />
                    </Grid>

                  </>
                }

                <Grid className={classes.buttonContainer} container item xs={12} alignItems="center" justify="space-between">
                  <Grid item xs={5}></Grid>
                  <Grid className='mr-2' container item xs={5} alignItems="center">
                    <Grid item xs={6}>
                      <button className={classes.cancelButton} onClick={() => { dispatch(UiActions.switchCloseAddNewPlant()) }}>Indietro</button>
                    </Grid>
                    <Grid item xs={6}>
                      <button data-cy="confirm" className={classes.confirmButton} type="submit" onClick={() => { }}>Conferma</button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}